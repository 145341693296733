import React from "react"
import { Link } from "gatsby"

import Layout from "../layout/index"
import Image from "../components/image"
import SEO from "../components/seo"

import store from '../images/landing/chocolate_main.jpeg'

import chocolates from '../images/landing/chocolate_rose_alice.jpg'
import bakedgoods from '../images/landing/baked_goods.jpg'
import cakes from '../images/cake_lettering.jpg'


const IndexPage = (props) => (
  <div>
    <SEO title="Home" />

    <section id="one" className="main style1">
        <div className="grid-wrapper">
            <div className="col-6">
                <header className="major">
                    <h1>Treats by Eunice</h1>
                </header>
                <p>Quality gifts for all. <br /> 
                Happy baked goods for everyone.  <br /> 
                Genuinely handcrafted with love and care.</p>
            </div>
            <div className="col-6">
                <span className="image fit"><img src={store} alt="" /></span>
            </div>
        </div>
    </section>

    <section id="two" className="main style3 special">
        <div className="grid-wrapper">
            <div className="col-12">
                <header className="major">
                    <h2>Products</h2>
                </header>
                <p className="producttext">
                Our creations are always made with the finest ingredients for all our customers.<br />
                We can honestly ensure our hand-crafted products reach a new level of confection perfection.
                </p>
            </div>

            <div className="col-4">
                <span className="image fit"><img src={chocolates} alt="" /></span>
                <h3>Chocolates</h3>
                <p>Our fineset chocolates always made with top quality ingredients. You will want to indulge in each and every decadent flavor.</p>
                <ul className="actions">
                    <li><a href="/product#chocolates" className="button">More</a></li>
                </ul>
            </div>
            <div className="col-4">
                <span className="image fit"><img src={cakes} alt="" /></span>
                <h3>Cakes</h3>
                <p>Creating your very-own personalized cakes has never been easier with Treats by Eunice. Make your special days more memorable.</p>
                <ul className="actions">
                    <li><a href="/product#cakes" className="button">More</a></li>
                </ul>
            </div>
            <div className="col-4">
                <span className="image fit"><img src={bakedgoods} alt="" /></span>
                <h3>Baked Sweets</h3>
                <p>Fresh ingredients make difference. Our freshly baked sweets are so satisfying that you will crave for more. </p>
                <ul className="actions">
                    <li><a href="/product#baked" className="button">More</a></li>
                </ul>
            </div>

        </div>
    </section>

    <section id="three" className="main style2 special">
        <div className="container">
            <header className="major">
                <h2>Baking Classes</h2>
            </header>
            <p className="classKrn">베이킹 수업 일정은 아래의 블로그를 통해 확인해주세요.</p>
            <ul className="actions uniform">
                <li><a href="https://blog.naver.com/eunicetreats" target="_blank" rel="noopener noreferrer" className="button special">Go to blog</a></li>
            </ul>
        </div>
    </section>
  </div>
)

export default IndexPage
